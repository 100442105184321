import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type {
  RawParams,
  Transition,
  TransitionOptions,
  UIRouter,
} from '@uirouter/core';

import * as application from 'store/application';
import { IRootStore } from 'store/index';

export function RouterController(
  params: ControllerParams,
  store: IRootStore,
  router: UIRouter,
) {
  router.transitionService.onBefore({}, handleRouterUpdate);
  router.transitionService.onError({}, handleRouterUpdate);

  return {
    router$: {
      go(state: string, params?: RawParams, options?: TransitionOptions) {
        router.stateService.go(state, params, options);
      },
      reload(state?: string) {
        router.stateService.reload(state);
      },
    },
  };

  async function handleRouterUpdate(transition: Transition) {
    store.dispatch(
      application.actions.setRouterCurrent({
        url: router.urlService.url(),
        state: transition.to().name as string,
        params: transition.params('to'),
      }),
    );
  }
}

export type IRouterController = ReturnType<typeof RouterController>;
