import { dsl } from '@wix/yoshi-serverless/wrap';

export const getGroup = dsl({
          functionName: 'getGroup',
          fileName: 'api/groups/groups.api',
        });

export const updateGroup = dsl({
          functionName: 'updateGroup',
          fileName: 'api/groups/groups.api',
        });

export const listPendingRequests = dsl({
          functionName: 'listPendingRequests',
          fileName: 'api/groups/groups.api',
        });

export const getPendingRequestsCount = dsl({
          functionName: 'getPendingRequestsCount',
          fileName: 'api/groups/groups.api',
        });

export const getJoinedGroups = dsl({
          functionName: 'getJoinedGroups',
          fileName: 'api/groups/groups.api',
        });