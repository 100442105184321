import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as events from 'store/events';
import type { IRootStore } from 'store/index';

export function EventsController(params: ControllerParams, store: IRootStore) {
  return {
    events$: {
      fetch,
    },
  };

  function fetch(groupId: string) {
    return store.dispatch(events.thunks.fetch(groupId));
  }
}

export type IEventsController = ReturnType<typeof EventsController>;
