import {
  servicesPlugin,
  MemoryLocationConfig,
  MemoryLocationService,
} from '@uirouter/core';

import type {
  LocationConfig,
  LocationServices,
  UIRouter,
} from '@uirouter/core';

import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { WixLocationConfig, PushLocationService } from './core';

export function controllerPlugin(flowApi: ControllerFlowAPI) {
  return function (router: UIRouter) {
    const {
      environment,
      controllerConfig: { wixCodeApi },
    } = flowApi;

    let configuration: LocationConfig;
    let service: LocationServices;

    if (environment.isViewer) {
      configuration = new WixLocationConfig(wixCodeApi.location);
      service = new PushLocationService(router, wixCodeApi.location);
    } else {
      configuration = new MemoryLocationConfig();
      service = new MemoryLocationService(router);
    }

    router.locationConfig = configuration;
    router.locationService = service;

    router.plugin(servicesPlugin);
    router.stateService.defaultErrorHandler(transitionErrorHandler);
    router.urlService.deferIntercept();

    router.stateRegistry.decorator('resolvePolicy', (state) => {
      const { isSSR } = environment;
      const { resolvePolicy } = state;

      return {
        when: resolvePolicy?.when || 'EAGER',
        async: resolvePolicy?.async || isSSR ? 'WAIT' : 'NOWAIT',
      };
    });

    return {
      name: 'WixUIRouterControllerPlugin',
      dispose(router: UIRouter) {
        router.dispose(configuration);
        router.dispose(service);
      },
    };

    function transitionErrorHandler(error: any) {
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
    }
  };
}
