import { merge, omit, cloneDeep } from 'lodash';
import { UIRouter } from '@uirouter/core';
import type { ControllerParams } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';

import type {
  I$WWrapper,
  IWidgetController,
} from '@wix/native-components-infra/dist/src/types/types';

import { selectStateDeclarations } from 'store/application/selectors';
import { actions } from 'store/application';
import { injectWarmupData } from 'store/actions';
import { STORE_WARMUP_DATA_KEY, ROUTER_WARMUP_DATA_KEY } from 'store/constants';
import type { IRootStore } from 'store/types';
import { createStore } from 'store/index';

import type { Resolvables } from 'router/helpers';
import { controllerPlugin } from 'router/controller-plugin';

import { ApplicationController } from './application.controller';
import { CentralFeedController } from './central-feed.controller';
import { CommentsController } from './comments.controller';
import { EventsController } from './events.controller';
import { FeedController } from './feed.controller';
import { FilesController } from './files.controller';
import { GroupController } from './group.controller';
import { GroupsController } from './groups.controller';
import { MediaController } from './media.controller';
import { MembersController } from './members.controller';
import { RouterController } from './router.controller';
import { TopicsController } from './topics.controller';

import { getAppData } from './helpers';
import { ESettingsEvents, IControllerVM, SettingsEventHandler } from './types';

export class WixGroupsController implements IWidgetController {
  public isMocked: boolean;
  public store: IRootStore;
  public router: UIRouter;
  public vm: IControllerVM;

  protected settingsEvents: ReturnType<typeof createEventHandler>;

  constructor(public params: ControllerParams) {
    const { isMocked, routes } = getAppData(params);

    const { flowAPI } = params;
    const { config, platformAPIs, wixCodeApi } = flowAPI.controllerConfig;

    this.router = new UIRouter();
    this.router.plugin(controllerPlugin(flowAPI));

    this.store = createStore(flowAPI);
    this.isMocked = !!isMocked;

    const commentsController = CommentsController(this.params, this.store);

    this.vm = merge(
      commentsController,
      ApplicationController(this.params, this.store, this.router),
      CentralFeedController(this.params, commentsController, this.store),
      CommentsController(this.params, this.store),
      EventsController(this.params, this.store),
      FeedController(this.params, commentsController, this.store),
      FilesController(this.params, this.store),
      GroupController(this.params, this.store, this.router),
      GroupsController(this.params, this.store),
      MediaController(this.params, this.store),
      MembersController(this.params, this.store),
      RouterController(this.params, this.store, this.router),
      TopicsController(this.params, this.store),
    );

    this.store.dispatch(
      actions.setAppData({ metaSiteId: platformAPIs.bi?.metaSiteId }),
    );

    this.store.dispatch(
      actions.setupRouter({
        states: routes,
        baseUrl: wixCodeApi.location.baseUrl,
      }),
    );

    this.settingsEvents = createEventHandler<SettingsEventHandler>(
      config.publicData.COMPONENT,
    );

    this.settingsEvents.on(
      ESettingsEvents.RequestState,
      this.handleRouterStateChangeRequest.bind(this),
    );

    this.settingsEvents.onReset(this.handleSettingsReset.bind(this));

    this.setupStates();
  }

  setupStates() {
    const { vm, isMocked } = this;
    const states = selectStateDeclarations(this.store.getState());

    if (isMocked) {
      return;
    }

    this.router.stateRegistry.register({
      ...states['social-groups'],
      resolve: [
        {
          token: 'user',
          policy: { async: 'WAIT' },
          async resolveFn() {
            const user = await vm.application$.fetchUserProfile().unwrap();

            return user;
          },
        },
      ],
    });

    for (const [key, state] of Object.entries(states)) {
      if (key !== 'social-groups') {
        this.router.stateRegistry.register(cloneDeep(state));
      }
    }
  }

  async pageReady() {
    if (!this.isMocked) {
      await this.resolve();
    }

    this.setInitialProps();

    if (!this.isMocked) {
      this.router.urlService.listen();
    }

    this.store.subscribe(this.updateStore.bind(this));
  }

  updateConfig($w: I$WWrapper, updatedConfig: { [key: string]: any }) {
    this.settingsEvents.notify(updatedConfig.publicData.COMPONENT || {});
  }

  onBeforeUnLoad() {
    this.vm._.comments.dispose();
    this.router.dispose();
  }

  protected async resolve() {
    const { flowAPI } = this.params;
    const { wixCodeApi } = flowAPI.controllerConfig;
    const { warmupData } = wixCodeApi.window;
    const { isSSR } = flowAPI.environment;

    if (!isSSR) {
      const state = warmupData.get(STORE_WARMUP_DATA_KEY);
      const resolvables = warmupData.get(ROUTER_WARMUP_DATA_KEY);

      this.store.dispatch(injectWarmupData(state));
      this.handleTransition(resolvables).catch(() => {});

      return;
    }

    const resolvables = await this.handleTransition().catch(() => ({}));

    warmupData.set(ROUTER_WARMUP_DATA_KEY, resolvables);
    warmupData.set(STORE_WARMUP_DATA_KEY, this.store.getState());
  }

  public handleTransition(resolvables?: Resolvables): Promise<Resolvables> {
    return Promise.resolve({});
  }

  public handleSettingsReset() {}

  public handleRouterStateChangeRequest(state: string) {
    this.router.stateService.go(state);
  }

  protected updateStore() {
    const { setProps } = this.params.flowAPI.controllerConfig;

    setProps({
      store: this.store.getState(),
    });
  }

  public setInitialProps() {
    const { setProps } = this.params.flowAPI.controllerConfig;

    setProps({
      ...omit(this.vm, '_'),
      fitToContentHeight: true,
      store: this.store.getState(),
    });

    this.vm._.comments.bind();
  }
}
