import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as media from 'store/media';
import type { IRootStore } from 'store/index';

export function MediaController(params: ControllerParams, store: IRootStore) {
  return {
    media$: {
      fetch,
    },
  };

  function fetch(groupId: string) {
    return store.dispatch(media.thunks.fetch(groupId));
  }
}

export type IMediaController = ReturnType<typeof MediaController>;
