import type { CommentsProps } from '@wix/comments-ooi-client';

import type { IRootState } from 'store/types';

import type { IApplicationController } from './application.controller';
import type { ICentralFeedController } from './central-feed.controller';
import type { ICommentsController } from './comments.controller';
import type { IEventsController } from './events.controller';
import type { IFeedController } from './feed.controller';
import type { IFilesController } from './files.controller';
import type { IGroupController } from './group.controller';
import type { IGroupsController } from './groups.controller';
import type { IMediaController } from './media.controller';
import type { IMembersController } from './members.controller';
import type { IRouterController } from './router.controller';
import type { ITopicsController } from './topics.controller';

export type IControllerVM = IApplicationController &
  ICentralFeedController &
  ICommentsController &
  IEventsController &
  IFeedController &
  IFilesController &
  IGroupController &
  IGroupsController &
  IMediaController &
  IMembersController &
  IRouterController &
  ITopicsController;

export type IControllerMethods = Omit<IControllerVM, '_'>;

export type IControllerProps = IControllerMethods &
  CommentsProps & { store: IRootState };

export enum ESettingsEvents {
  RequestState = 'requestState',
}

export type SettingsEventHandler = {
  [ESettingsEvents.RequestState]: string;
};
