import { createAsyncThunk } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import {
  followMember,
  listMyMemberFollowing,
  unfollowMember,
} from '@wix/ambassador-members-v2-follow/http';
import { getMyMember } from '@wix/ambassador-members-v1-member/http';
import { Set as WixMembersFieldSet } from '@wix/ambassador-members-v1-member/types';

import { mapMember } from 'api/members/selectors';

import type { IApplicationUser } from './types';

import * as application from '.';

export const follow = createAsyncThunk(
  'application:follow',
  async (memberId: string, thunkAPI) => {
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = flowApi.translations;

    try {
      await flowApi.httpClient.request(
        followMember({
          memberId,
        }),
      );

      return memberId;
    } catch (err) {
      const error = err as Error;
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      dispatch(
        application.actions.showToast({
          type: 'error',
          message: t('groups-web.toast.error.application.follow'),
          description: error.message,
        }),
      );
      return Promise.reject(error);
    }
  },
);

export const unfollow = createAsyncThunk(
  'application:unfollow',
  async (memberId: string, thunkAPI) => {
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = flowApi.translations;

    try {
      await flowApi.httpClient.request(
        unfollowMember({
          memberId,
        }),
      );

      return memberId;
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.application.unfollow'),
        }),
      );
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const login = createAsyncThunk(
  'application:login',
  async (_, thunkAPI) => {
    const {
      controllerConfig: { wixCodeApi },
    } = thunkAPI.extra as ControllerFlowAPI;

    try {
      await wixCodeApi.user.promptLogin({ modal: true });
      return thunkAPI.dispatch(fetchCurrentUserProfile());
    } catch (err) {
      return Promise.reject();
    }
  },
);

export const fetchCurrentUserProfile = createAsyncThunk(
  'application:fetchCurrentUserProfile',
  async function (_, thunkAPI) {
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { t } = flowApi.translations;
    const { wixCodeApi } = flowApi.controllerConfig;

    const wixUser = {
      ...wixCodeApi.user.currentUser,
      instance: wixCodeApi.site.getAppToken?.(
        flowApi.environment.appDefinitionId,
      ),
    };

    const user = pick(
      {
        ...wixCodeApi.user.currentUser,
        instance: wixCodeApi.site.getAppToken?.(
          flowApi.environment.appDefinitionId,
        ),
      },
      'loggedIn',
      'role',
      'instance',
    );

    if (!wixUser.loggedIn) {
      return {
        following: [],
        siteMemberId: wixUser.id,
        ...user,
      };
    }

    try {
      const [response, following] = await Promise.all([
        flowApi.httpClient.request(
          getMyMember({
            fieldsets: [WixMembersFieldSet.FULL],
          }),
        ),
        flowApi.httpClient.request(listMyMemberFollowing({})).catch(() => ({
          data: { memberIds: [] },
        })),
      ]);

      if (!response.data.member) {
        throw new Error('Failed to fetch current member');
      }

      return {
        ...user,
        ...mapMember(response.data.member),
        following: following.data.memberIds || [],
      } as IApplicationUser;
    } catch (err) {
      const error = err as Error;
      thunkAPI.dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.application.currentUserProfile'),
        }),
      );
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);
