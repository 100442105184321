import { createSlice } from '@reduxjs/toolkit';

import {
  PagingMetadata,
  Role,
} from '@wix/ambassador-social-groups-v2-group-member-with-profile/types';

import { injectWarmupData } from '../actions';
import { membersAdapter, joinRequestsAdapter } from './adapter';
import * as thunks from './thunks';

type IStatus = {
  loading: boolean;
  success?: boolean;
  error: boolean;
};

type IEntityStatus = Record<string, IStatus>;

export const initialState = membersAdapter.getInitialState({
  metadata: {} as PagingMetadata,
  status: {
    fetch: {} as IStatus,
    fetchMore: {} as IStatus,
    fetchProfile: {} as IEntityStatus,
    remove: {} as IEntityStatus,
    answers: {} as IEntityStatus,
    changeRole: {} as IEntityStatus,
  },

  suggested: membersAdapter.getInitialState({
    metadata: {} as PagingMetadata,
    status: {
      fetch: {} as IStatus,
      invite: {} as IEntityStatus,
    },
  }),

  requests: joinRequestsAdapter.getInitialState({
    metadata: {} as PagingMetadata,
    status: {
      fetch: {} as IStatus,
      approve: {} as IEntityStatus,
      reject: {} as IEntityStatus,
    },
  }),
});

export const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      injectWarmupData,
      (state, action) => action.payload?.members || state,
    );

    builder
      .addCase(thunks.fetchAnswers.pending, (state, action) => {
        const { memberId } = action.meta.arg;

        state.status.answers[memberId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.fetchAnswers.rejected, (state, action) => {
        const { memberId } = action.meta.arg;

        state.status.answers[memberId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.fetchAnswers.fulfilled, (state, action) => {
        const { memberId } = action.meta.arg;

        state.status.answers[memberId] = {
          loading: false,
          error: false,
        };

        membersAdapter.updateOne(state, {
          id: memberId,
          changes: {
            answers: action.payload,
          },
        });
      });

    builder
      .addCase(thunks.remove.pending, (state, action) => {
        const { memberId } = action.meta.arg;

        state.status.remove[memberId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.remove.rejected, (state, action) => {
        const { memberId } = action.meta.arg;

        state.status.remove[memberId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.remove.fulfilled, (state, action) => {
        const { memberId } = action.meta.arg;

        state.status.remove[memberId] = {
          loading: false,
          error: false,
        };

        state.metadata.total! -= 1;

        membersAdapter.removeOne(state, memberId);
      });

    builder
      .addCase(thunks.fetchProfile.pending, (state, action) => {
        const memberId = action.meta.arg;

        state.status.fetchProfile[memberId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.fetchProfile.rejected, (state, action) => {
        const memberId = action.meta.arg;

        state.status.fetchProfile[memberId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.fetchProfile.fulfilled, (state, action) => {
        const memberId = action.meta.arg;

        state.status.fetchProfile[memberId] = {
          loading: false,
          error: false,
        };

        membersAdapter.addOne(state, {
          ...action.payload,
          role: Role.UNKNOWN_ROLE,
        });
      });

    builder
      .addCase(thunks.changeRole.pending, (state, action) => {
        const { memberId } = action.meta.arg;

        state.status.changeRole[memberId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.changeRole.rejected, (state, action) => {
        const { memberId } = action.meta.arg;

        state.status.changeRole[memberId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.changeRole.fulfilled, (state, action) => {
        const { memberId, role } = action.meta.arg;

        state.status.changeRole[memberId] = {
          loading: false,
          error: false,
        };

        membersAdapter.updateOne(state, {
          id: memberId,
          changes: {
            role,
          },
        });
      });

    builder.addCase(thunks.fetchJoinRequests.pending, (state, action) => {
      state.requests.status.fetch.loading = true;
      state.requests.status.fetch.error = false;
    });

    builder.addCase(thunks.fetchJoinRequests.rejected, (state, action) => {
      state.requests.status.fetch.loading = false;
      state.requests.status.fetch.error = true;
    });

    builder.addCase(thunks.fetchJoinRequests.fulfilled, (state, action) => {
      state.requests.status.fetch.loading = false;
      state.requests.status.fetch.error = false;

      state.requests.metadata = action.payload.metadata;
      joinRequestsAdapter.setAll(state.requests, action.payload.requests);
    });

    builder
      .addCase(thunks.query.pending, (state, action) => {
        const { offset } = action.meta.arg;

        if (offset) {
          state.status.fetchMore.loading = true;
          state.status.fetchMore.error = false;
          return;
        }

        state.status.fetch.loading = true;
        state.status.fetch.error = false;
      })
      .addCase(thunks.query.rejected, (state, action) => {
        const { offset } = action.meta.arg;

        if (offset) {
          state.status.fetchMore.loading = false;
          state.status.fetchMore.error = true;
          return;
        }

        state.status.fetch.loading = false;
        state.status.fetch.error = true;
      })
      .addCase(thunks.query.fulfilled, (state, action) => {
        const { offset } = action.meta.arg;

        state.status.fetch.loading = false;
        state.status.fetch.error = false;
        state.status.fetchMore.loading = false;
        state.status.fetchMore.error = false;

        state.metadata = action.payload.metadata || {};

        if (offset) {
          membersAdapter.addMany(state, action.payload.members);
        } else {
          membersAdapter.setAll(state, action.payload.members);
        }
      });

    builder
      .addCase(thunks.list.pending, (state) => {
        state.status.fetch.loading = true;
        state.status.fetch.error = false;
      })
      .addCase(thunks.list.rejected, (state) => {
        state.status.fetch.loading = false;
        state.status.fetch.error = true;
      })
      .addCase(thunks.list.fulfilled, (state, action) => {
        state.status.fetch.loading = false;
        state.status.fetch.error = false;

        membersAdapter.setAll(state, action.payload);
      });

    builder
      .addCase(thunks.querySuggested.pending, (state, action) => {
        const { offset } = action.meta.arg;

        if (offset) {
          return;
        }

        state.suggested.status.fetch.loading = true;
        state.suggested.status.fetch.error = false;
      })
      .addCase(thunks.querySuggested.rejected, (state, action) => {
        const { offset } = action.meta.arg;

        if (offset) {
          return;
        }

        state.suggested.status.fetch.loading = false;
        state.suggested.status.fetch.error = true;
      })
      .addCase(thunks.querySuggested.fulfilled, (state, action) => {
        const { offset } = action.meta.arg;

        state.suggested.status.fetch.loading = false;
        state.suggested.status.fetch.error = false;

        state.suggested.metadata = action.payload.metadata || {};

        if (offset) {
          membersAdapter.addMany(state.suggested, action.payload.members);
        } else {
          membersAdapter.setAll(state.suggested, action.payload.members);
        }
      });

    builder
      .addCase(thunks.approve.pending, (state, action) => {
        const { memberId } = action.meta.arg;

        state.requests.status.approve[memberId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.approve.rejected, (state, action) => {
        const { memberId } = action.meta.arg;

        state.requests.status.approve[memberId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.approve.fulfilled, (state, action) => {
        const { memberId } = action.meta.arg;

        state.requests.status.approve[memberId] = {
          loading: false,
          error: false,
        };

        joinRequestsAdapter.removeOne(state.requests, memberId);
        membersAdapter.addOne(state, action.payload);
      });

    builder
      .addCase(thunks.reject.pending, (state, action) => {
        const { memberId } = action.meta.arg;

        state.requests.status.reject[memberId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.reject.rejected, (state, action) => {
        const { memberId } = action.meta.arg;

        state.requests.status.reject[memberId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.reject.fulfilled, (state, action) => {
        const { memberId } = action.meta.arg;

        state.requests.status.reject[memberId] = {
          loading: false,
          error: false,
        };

        joinRequestsAdapter.removeOne(state.requests, memberId);
      });

    builder
      .addCase(thunks.inviteByEmail.pending, (state, action) => {
        const { emails } = action.meta.arg;

        emails.forEach((email) => {
          state.suggested.status.invite[email] = {
            loading: true,
            error: false,
            success: false,
          };
        });
      })
      .addCase(thunks.inviteByEmail.rejected, (state, action) => {
        const { emails } = action.meta.arg;

        emails.forEach((email) => {
          state.suggested.status.invite[email] = {
            loading: false,
            success: false,
            error: true,
          };
        });
      })
      .addCase(thunks.inviteByEmail.fulfilled, (state, action) => {
        const { emails } = action.meta.arg;

        emails.forEach((email) => {
          state.suggested.status.invite[email] = {
            loading: false,
            error: false,
            success: true,
          };
        });
      });

    builder
      .addCase(thunks.addToGroup.pending, (state, action) => {
        const { memberIds } = action.meta.arg;

        memberIds.forEach((memberId) => {
          state.suggested.status.invite[memberId] = {
            loading: true,
            error: false,
            success: false,
          };
        });
      })
      .addCase(thunks.addToGroup.rejected, (state, action) => {
        const { memberIds } = action.meta.arg;

        memberIds.forEach((memberId) => {
          state.suggested.status.invite[memberId] = {
            loading: false,
            error: true,
            success: false,
          };
        });
      })
      .addCase(thunks.addToGroup.fulfilled, (state, action) => {
        const { memberIds } = action.meta.arg;
        const members = action.payload;

        memberIds.forEach((memberId) => {
          state.suggested.status.invite[memberId] = {
            loading: false,
            error: false,
            success: true,
          };
        });

        membersAdapter.addMany(state, members);
        membersAdapter.removeMany(state.suggested, memberIds);
      });
  },
});
