import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as feed from 'store/feed';
import type { IRootStore } from 'store/index';

import type { ICommentsController } from './comments.controller';

export function CentralFeedController(
  params: ControllerParams,
  comments: ICommentsController,
  store: IRootStore,
) {
  return {
    centralFeed$: {
      fetch,
    },
  };

  async function fetch(cursor?: string, withComments = true) {
    const data = await store
      .dispatch(
        feed.thunks.fetchCentralFeed({
          cursor: {
            cursor,
            limit: 10,
          },
        }),
      )
      .unwrap();

    if (withComments) {
      await comments._.comments.fetch(data.items);
    }

    return data;
  }
}

export type ICentralFeedController = ReturnType<typeof CentralFeedController>;
